<template>
  <p class="clearfix mb-0">
    <span class="float-sm-left d-block d-sm-inline-block sm-25">
      © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.atacc.com.ar/"
        target="_blank"
      >A.T.A.C.C. </b-link>
      <span class="d-sm-inline-block"> - Todos los derechos reservados {{ versionApp }}</span>
    </span>

    <span class="float-sm-right d-block d-sm-inline-block sm-25">
      <span class="d-sm-inline-block">Desarrollado por </span>
      <b-link
        class="mr-25"
        href="https://www.trimix.com.ar/"
        target="_blank"
      > Trimix
      </b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      versionApp: 'v 1.2.7',
    }
  },
}
</script>
